<template>
  <fragment>
    <b-row>
      <b-col lg="4">
        <CardForm title="Foto Close Up" subTitle="Upload foto member">
          <template #form>
            <div class="card-body">
              <b-row>
                <b-col lg="12">
                  <div
                    v-if="form.imageId != null"
                    class="bgi-no-repeat bgi-size-cover rounded min-h-250px mb-2"
                    :style="`background-image: url(${mediaUrl(form.imageUrl)})`"
                    @click="show(mediaGroupEnum.MEMBER)"
                  ></div>
                  <input
                    type="file"
                    ref="imageFile"
                    style="display: none"
                    accept=".png, .jpg, .jpeg"
                    @change="handleUploadImage"
                  />
                  <b-button size="sm" block @click="$refs.imageFile.click()">
                    Buka Folder
                  </b-button>
                  <b-button
                    size="sm"
                    block
                    @click="toggleCamera(mediaGroupEnum.MEMBER)"
                  >
                    Buka Kamera
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </template>
        </CardForm>
        <CardForm
          title="Scan Dokumen Identitas"
          subTitle="Scan kartu identitas"
          class="mt-5"
        >
          <template #form>
            <div class="card-body">
              <b-row>
                <b-col lg="12">
                  <div
                    v-if="form.identityId != null"
                    class="bgi-no-repeat bgi-size-cover rounded min-h-150px mb-2"
                    :style="`background-image: url(${mediaUrl(
                      form.identityUrl
                    )})`"
                    @click="show(mediaGroupEnum.IDENTITY_CARD)"
                  ></div>
                  <input
                    type="file"
                    ref="identityFile"
                    style="display: none"
                    accept=".png, .jpg, .jpeg"
                    @change="handleUploadIdentity"
                  />
                  <b-button size="sm" block @click="$refs.identityFile.click()">
                    Buka Folder
                  </b-button>
                  <b-button
                    size="sm"
                    block
                    @click="toggleCamera(mediaGroupEnum.IDENTITY_CARD)"
                  >
                    Buka Kamera
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </template>
        </CardForm>
      </b-col>
      <b-col lg="8">
        <CardForm :backButton="true" :title="title" :subTitle="subTitle">
          <template #toolbar>
            <b-button variant="primary" @click="handleSubmit">
              {{ textButton }}
            </b-button>
          </template>
          <template #form>
            <b-form @submit="handleSubmit" autocomplete="off">
              <div class="card-body">
                <b-row>
                  <b-col lg="6">
                    <RadioGroup
                      label="Tipe Identitas"
                      v-model="form.identityType"
                      :useHorizontal="false"
                      :options="options.identityType"
                    />
                  </b-col>
                  <b-col lg="6">
                    <InputText
                      label="Nomor Identitas"
                      type="text"
                      v-model="form.nomorIdentitas"
                      :v="$v.form.nomorIdentitas"
                      :useHorizontal="false"
                    />
                  </b-col>
                  <b-col lg="6">
                    <RadioGroup
                      label="Sapaan"
                      v-model="form.salutation"
                      :useHorizontal="false"
                      :options="options.salutation"
                    />
                  </b-col>
                  <b-col lg="6">
                    <InputText
                      label="Nama Lengkap"
                      type="text"
                      v-model="form.fullName"
                      :v="$v.form.fullName"
                      :useHorizontal="false"
                    />
                  </b-col>
                  <b-col lg="6">
                    <InputText
                      label="Tempat Lahir"
                      type="text"
                      v-model="form.placeOfBirth"
                      :v="$v.form.placeOfBirth"
                      :useHorizontal="false"
                    />
                  </b-col>
                  <b-col lg="6">
                    <InputText
                      label="Tanggal Lahir"
                      type="date"
                      :description="ageCalculator(form.dateOfBirth)"
                      v-model="form.dateOfBirth"
                      :v="$v.form.dateOfBirth"
                      :useHorizontal="false"
                    />
                  </b-col>
                  <b-col lg="6">
                    <b-form-group label="Jenis Kelamin">
                      <b-form-radio-group
                        v-model="form.gender"
                        :options="options.gender"
                        buttons
                        button-variant="outline-success"
                      ></b-form-radio-group>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12">
                    <TextArea
                      label="Alamat"
                      type="text"
                      v-model="form.address"
                      :v="$v.form.address"
                      :useHorizontal="false"
                    />
                  </b-col>
                  <b-col lg="6">
                    <InputText
                      label="Email"
                      type="email"
                      v-model="form.email"
                      :v="$v.form.email"
                      :useHorizontal="false"
                    >
                      <template #prepend>
                        <b-input-group-text>
                          <i class="far fa-envelope"></i>
                        </b-input-group-text>
                      </template>
                    </InputText>
                  </b-col>
                  <b-col lg="6">
                    <InputText
                      label="Nomor WhatsApp"
                      type="tel"
                      description="Wajib diisi, nanti dipake untuk mengirim PIN masuk studio Fitness"
                      v-model="form.phone"
                      :v="$v.form.phone"
                      :useHorizontal="false"
                    >
                      <template #prepend>
                        <b-input-group-text>
                          <i class="fab fa-whatsapp"></i>
                        </b-input-group-text>
                      </template>
                    </InputText>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12">
                    <h5 class="font-weight-bold mt-10 mb-6">Kartu Member</h5>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6">
                    <InputText
                      label="RFID"
                      type="password"
                      placeholder="Tarok kursor disini dan silahkan scan"
                      v-model="form.rfidNumber"
                      :v="$v.form.rfidNumber"
                      :useHorizontal="false"
                      @input="getCardByRfid"
                    >
                      <template #prepend>
                        <b-input-group-text>
                          <i class="fas fa-barcode"></i>
                        </b-input-group-text>
                      </template>
                    </InputText>
                  </b-col>
                  <b-col lg="6">
                    <InputText
                      label="Nomor Kartu"
                      type="text"
                      v-model="form.cardNumber"
                      :v="$v.form.cardNumber"
                      :useHorizontal="false"
                      disabled
                    >
                      <template #prepend>
                        <b-input-group-text>
                          <i class="far fa-credit-card"></i>
                        </b-input-group-text>
                      </template>
                    </InputText>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </template>
        </CardForm>
      </b-col>
    </b-row>

    <b-modal
      v-model="modal.show"
      title="Kamera"
      size="lg"
      :no-close-on-backdrop="true"
      :hide-header-close="true"
      :hide-footer="true"
    >
      <div class="web-camera-container">
        <div
          v-if="isCameraOpen"
          v-show="!isLoading"
          class="camera-box"
          :class="{ flash: isShotPhoto }"
        >
          <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>
          <video
            v-show="!isPhotoTaken"
            ref="camera"
            :width="450"
            :height="337.5"
            autoplay
          ></video>
          <canvas
            v-show="isPhotoTaken"
            id="photoTaken"
            ref="canvas"
            :width="450"
            :height="337.5"
          ></canvas>
        </div>

        <div v-if="isCameraOpen && !isLoading">
          <div class="text-center">
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="toggleCamera"
            >
              Tutup
            </b-button>
            <b-button
              class="btn btn-icon btn-circle btn-clean btn-lg w-40px h-40px ml-4 mr-4 p-10"
              v-b-tooltip.hover
              :title="!isPhotoTaken ? `Ambil foto` : `Ambil ulang foto`"
              size="sm"
              variant="outline-secondary"
              @click="takePhoto"
            >
              <i class="flaticon-photo-camera icon-3x"></i>
            </b-button>
            <b-button
              :disabled="!isPhotoTaken"
              size="sm"
              variant="outline-secondary"
              @click="handleUploadWebCam"
            >
              Upload
            </b-button>
          </div>
        </div>

        <!-- <div v-if="isPhotoTaken && isCameraOpen" class="camera-download">
          <a
            id="downloadPhoto"
            download="my-photo.jpg"
            class="button"
            role="button"
            @click="handleUploadWebCam"
          >
            Download
          </a>
        </div> -->
      </div>
    </b-modal>

    <b-modal
      v-model="modalNotification.show"
      title="Registrasi Member Selesai"
      :no-close-on-backdrop="true"
      :hide-header-close="true"
      :ok-only="true"
      ok-title="Tutup"
    >
      <lottie-vue-player
        :src="`/media/animation/lottie-success.json`"
        :autoplay="true"
        :loop="true"
        style="width: 100%; height: 150px"
      />
      <div class="mb-4 text-center">
        <span class="font-size-h4 font-weight-bolder">
          {{ modalNotification.message }}
        </span>
      </div>
    </b-modal>

    <viewer :images="images">
      <img v-for="src in images" :key="src" :src="src" />
    </viewer>
  </fragment>
</template>

<style>
.web-camera-container {
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;

  .camera-button {
    margin-bottom: 2rem;
  }

  .camera-box {
    .camera-shutter {
      opacity: 0;
      width: 450px;
      height: 337.5px;
      background-color: #fff;
      position: absolute;

      &.flash {
        opacity: 1;
      }
    }
  }

  .camera-shoot {
    margin: 1rem 0;

    button {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      img {
        height: 35px;
        object-fit: cover;
      }
    }
  }

  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 3rem 0 0 -1.2rem;

    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }

    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>

<script>
import { email, numeric, required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { SET_MEMBER } from "@/core/services/store/pos.module.js";
import { mediaGroupEnum } from "@/core/datasource/enum";
import { mediaUrl, dateFormat, ageCalculator } from "@/core/utils";

export default {
  data: () => ({
    route: {
      form: "memberCreate",
      table: "member",
    },

    isCameraOpen: false,
    isPhotoTaken: false,
    isShotPhoto: false,
    isLoading: false,

    form: {
      imageId: null,
      imageUrl: null,
      identityId: null,
      identityUrl: null,
      identityType: 0,
      nomorIdentitas: null,
      salutation: "Kak",
      fullName: null,
      placeOfBirth: null,
      dateOfBirth: null,
      gender: 0,
      bloodType: null,
      address: null,
      neighbourhood: null,
      hamlet: null,
      village: null,
      subDistrict: null,
      city: null,
      province: null,
      religion: null,
      job: null,
      citizenship: null,
      validUntil: null,
      bodyHeight: null,
      email: null,
      phone: null,
      rfidNumber: null,
      cardNumber: null,
    },
    options: {
      salutation: [
        { text: "Kak", value: "Kak" },
        { text: "Pak", value: "Pak" },
        { text: "Bu", value: "Bu" },
        { text: "Om", value: "Om" },
        { text: "Tante", value: "Tante" },
        { text: "Dek", value: "Dek" },
      ],
      identityType: [
        { text: "KTP", value: 0 },
        { text: "SIM", value: 1 },
        { text: "Smart SIM", value: 2 },
        { text: "Kartu Pelajar", value: 3 },
      ],
      gender: [
        { text: "Pria", value: 0 },
        { text: "Wanita", value: 1 },
      ],
    },
    modal: {
      show: false,
      group: null,
    },
    modalNotification: {
      show: false,
      message: null,
    },
    mediaGroupEnum,
    images: [],
  }),
  validations: {
    form: {
      nomorIdentitas: { required },
      fullName: { required },
      placeOfBirth: { required },
      dateOfBirth: { required },
      address: { required },
      email: { email },
      phone: { required, numeric },
      rfidNumber: { required },
      cardNumber: { required },
    },
  },
  computed: {
    ...mapGetters(["store", "member"]),
    title() {
      const self = this;
      return self.$route.name != self.route.form
        ? "Ubah member"
        : "Registrasi Member Baru";
    },
    subTitle() {
      const self = this;
      return self.$route.name != self.route.form
        ? "Ubah member"
        : "Buat member baru";
    },
    textButton() {
      const self = this;
      return self.$route.name != self.route.form
        ? "Simpan Perubahan"
        : "Selesaikan Registrasi Member";
    },
  },
  created() {
    const self = this;

    if (self.$route.name != self.route.form) {
      self.getById();
    }
  },
  /* created() {
    const self = this;
    self.form = {
      imageId: null,
      imageUrl: null,
      identityId: null,
      identityUrl: null,
      identityType: 0,
      nomorIdentitas: "1671082209890010",
      salutation: "Kak",
      fullName: "Habibullah",
      placeOfBirth: "Tanjung Batu",
      dateOfBirth: "1989-09-22",
      gender: 0,
      bloodType: "A",
      address: "Jalan MRS Ganda Subrata sub Jalan Al Hikmah Hara Home 3B",
      neighbourhood: "52",
      hamlet: "08",
      village: "Sukamaju",
      subDistrict: "Sako",
      city: "Palembang",
      province: "Sumatera Selatan",
      religion: "Islam",
      job: "Karyawan Swasta",
      citizenship: "Indonesia",
      validUntil: "Seumur Hidup",
      bodyHeight: 72.5,
      email: "habeb@damaco.dev",
      phone: "085267625030",
      rfidNumber: "123",
      cardNumber: "1234",
    };
  }, */
  methods: {
    mediaUrl,
    dateFormat,
    ageCalculator,
    show(group) {
      const self = this;

      if (self.form.imageUrl != null) {
        self.$viewerApi({
          images: [
            group == mediaGroupEnum.MEMBER
              ? self.mediaUrl(self.form.imageUrl)
              : self.mediaUrl(self.form.identityUrl),
          ],
        });
      }
    },
    toggleCamera(group = null) {
      const self = this;
      self.modal.group = group;

      if (self.isCameraOpen) {
        self.modal.show = false;
        self.isCameraOpen = false;
        self.isPhotoTaken = false;
        self.isShotPhoto = false;
        self.stopCameraStream();
      } else {
        self.modal.show = true;
        self.isCameraOpen = true;
        self.createCameraElement();
      }
    },
    createCameraElement() {
      const self = this;
      self.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          self.isLoading = false;
          self.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          self.isLoading = false;
          self.$message.error({
            zIndex: 10000,
            message: error,
          });
        });
    },
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },
    takePhoto() {
      const self = this;
      if (!self.isPhotoTaken) {
        self.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          self.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      self.isPhotoTaken = !self.isPhotoTaken;

      const context = self.$refs.canvas.getContext("2d");
      context.drawImage(self.$refs.camera, 0, 0, 450, 337.5);
    },
    handleUploadWebCam() {
      const self = this;

      const canvas = document
        .getElementById("photoTaken")
        .toDataURL("image/jpeg");

      let params = {
        group: self.modal.group,
        file: canvas,
        append: false,
      };
      self.$store
        .dispatch("apis/post", {
          url: `/media/base64`,
          params,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            if (self.modal.group == mediaGroupEnum.MEMBER) {
              self.form.imageId = response.data.id;
              self.form.imageUrl = response.data.url;
            } else if (self.modal.group == mediaGroupEnum.IDENTITY_CARD) {
              self.form.identityId = response.data.id;
              self.form.identityUrl = response.data.url;
            }
          }
        })
        .finally(() => self.toggleCamera());
    },

    getById() {
      const self = this;

      let loader = self.$loading.show();
      self.$store
        .dispatch("apis/get", {
          url: `/store/${self.store.id}/member/${self.$route.params.id}`,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });

            self.$router.push({ name: self.route.table });
          } else {
            /* self.form = {
              imageId: response.data.logo?.id ?? null,
              imageUrl: response.data.logo?.label ?? null,
              storeName: response.data.storeName,
              services: response.data.services,
              address: response.data.address,
              city: response.data.city,
              province: response.data.province,
              taxPercentage: response.data.taxPercentage,
              posSystem: response.data.posSystem,
              clubMemberSystem: response.data.clubMemberSystem,
              actived: response.data.actived,
            }; */
            self.form = {
              imageId: response.data?.image?.id,
              imageUrl: response.data?.image?.label,
              identityId: response.data?.identity?.id,
              identityUrl: response.data?.identity?.label,
              identityType: response.data.identityType?.id,
              nomorIdentitas: response.data.nomorIdentitas,
              salutation: response.data.salutation,
              fullName: response.data.fullName,
              placeOfBirth: response.data.placeOfBirth,
              dateOfBirth:
                response.data.dateOfBirth != null
                  ? dateFormat(response.data.dateOfBirth, "YYYY-MM-DD")
                  : null,
              gender: response.data.gender?.id,
              bloodType: response.data.bloodType,
              address: response.data.address,
              neighbourhood: response.data.neighbourhood,
              hamlet: response.data.hamlet,
              village: response.data.village,
              subDistrict: response.data.subDistrict,
              city: response.data.city,
              province: response.data.province,
              religion: response.data.religion,
              job: response.data.job,
              citizenship: response.data.citizenship,
              validUntil: response.data.validUntil,
              bodyHeight: response.data.bodyHeight,
              email: response.data.email,
              phone: response.data.phone,
              rfidNumber: response.data.memberCard?.rfidNumber,
              cardNumber: response.data.memberCard?.cardNumber,
            };
          }
          loader.hide();
        });
    },
    getCardByRfid() {
      const self = this;

      if (self.store != null) {
        self.$store
          .dispatch("apis/get", {
            url: `/store/${self.store.id}/card/${self.form.rfidNumber}`,
          })
          .then((response) => {
            if (response.error) {
              self.form.rfidNumber = null;
              self.form.cardNumber = null;
            } else {
              self.form.rfidNumber = response.data.rfidNumber;
              self.form.cardNumber = response.data.cardNumber;
            }
          });
      }
    },
    handleSubmit() {
      const self = this;

      self.$v.form.$touch();
      if (self.$v.form.$pending || self.$v.form.$error) return;

      if (self.store != null) {
        let _confirmText = "",
          _okText = "",
          _action = "",
          _url = "";

        if (self.$route.name == self.route.form) {
          _confirmText = "Kamu yakin mau menyelesaikan registrasi member ini ?";
          _okText = "Ya, Selesaikan";
          _action = "apis/post";
          _url = `/store/${self.store.id}/member`;
        } else {
          _confirmText = "Kamu yakin mau mengubah data member ini ?";
          _okText = "Ya, Ubah";
          _action = "apis/put";
          _url = `/store/${self.store.id}/member/${self.$route.params.id}`;
        }

        self.$dialog
          .confirm(_confirmText, {
            okText: _okText,
            cancelText: "Ga jadi",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch(_action, {
                url: _url,
                params: self.form,
              })
              .then((response) => {
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  if (self.$route.name == self.route.form) {
                    self.modalNotification.message = response.message;
                    self.modalNotification.show = true;

                    self.$store.dispatch(SET_MEMBER, {
                      id: response.data.id,
                      fullName: response.data.fullName,
                      membershipNumber: response.data.membershipNumber,
                      email: response.data.email,
                      phone: response.data.phone,
                    });
                  } else {
                    self.$message.success({
                      zIndex: 10000,
                      message: response.message,
                    });
                    self.$router.go(-1);
                  }
                }
              })
              .finally(() => dialog.close());
          });
      }
    },
    handleUploadImage(event) {
      this.handleUpload(event, mediaGroupEnum.MEMBER);
    },
    handleUploadIdentity(event) {
      this.handleUpload(event, mediaGroupEnum.IDENTITY_CARD);
    },
    handleUpload(event, group) {
      if (event.target.files.length > 0) {
        const self = this;
        let payload = new FormData();

        event.target.files.forEach((item) => {
          payload.append("file", item);
        });
        payload.append("group", group);

        self.$store
          .dispatch("apis/upload", {
            url: "media",
            params: payload,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              if (group == mediaGroupEnum.MEMBER) {
                self.form.imageId = response.data.id;
                self.form.imageUrl = response.data.url;
              } else if (group == mediaGroupEnum.IDENTITY_CARD) {
                self.form.identityId = response.data.id;
                self.form.identityUrl = response.data.url;
              }
            }
          });
      }
    },
  },
};
</script>
